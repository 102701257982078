
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import CheckoutPanel from "@/components/checkout/CheckoutPanel.vue";
import { Cart, Order, UserCart } from "@/types/cart/Cart.interface";
import CartService from "@/service/cart-service/CartService";
import TransactionService from "@/service/transaction-service/TransactionService";
import { StatusChangeResponse } from "@/types/master/StatusChange.interface";
import ModalAlertError from "@/components/modal/ModalAlertError.vue";
import { useStore } from "@/store";
import { CartActionTypes } from "@/store/modules/cart/action-types";
import { CartStorePayload } from "@/store/modules/cart/actions";
import { GlobalActionTypes } from "@/store/modules/global/action-types";
import { TransactionActionTypes } from "@/store/modules/transaction/action-types";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  components: {
    CheckoutPanel,
    ModalAlertError,
  },
  setup() {
    const cartDetail = ref<UserCart>();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const cartService = ref(new CartService());
    const transactionService = ref(new TransactionService());
    const modalMessage = ref<string>("");
    const modalAlertError = ref<InstanceType<typeof ModalAlertError>>();
    const checkoutCartPanel = ref<InstanceType<typeof CheckoutPanel>>();
    const { cookies } = useCookies();

    const onHideModalError = () => {
      const payload = {
        branchId: store.getters.getBrand.branchId,
        socialReferenceId: store.getters.getUser.socialReferenceId,
      } as CartStorePayload;

      store.dispatch(CartActionTypes.GET_CART, payload);
    };

    const onBeforeSaveCart = async (
      cartFlag: number,
      promoCode: string,
      note: string
    ) => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, true);
      cartDetail.value = {
        referenceId: store.getters.getUser.socialReferenceId,
        typeId: store.getters.getPlatform,
        postId: store.getters.getCart.postId
          ? store.getters.getCart.postId
          : route.query.postId?.toString(),
        brandId: store.getters.getBrand.brandId,
        branchId: store.getters.getBrand.branchId,
        socialSalesChannel: store.getters.getSaleChannel,
        products: store.getters.getCart.products,
        freeProducts: store.getters.getCart.freeProducts,
        productDiscount: store.getters.getCart.productDiscount,
        SessionId: route.query.sessionId?.toString(),
        note: note,
        promoCode: promoCode,
      };
      //handle product in cart
      if (cartFlag == 0) {
        await onSaveCart(cartDetail.value);
      } else {
        await onSaveOrder(cartDetail.value);
      }
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
    };

    const onSaveCart = async (cart: UserCart) => {
      await cartService.value
        .onSaveCart(cart)
        .then(async (res: Cart) => {
          if (res) {
            await store.dispatch(CartActionTypes.SET_CART, res);
            store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
            checkoutCartPanel.value?.refreshData();

            if (res.promoCodeMessage) {
              modalMessage.value = res.promoCodeMessage;
              modalAlertError.value?.setShowModalError(true);
              checkoutCartPanel.value?.onRemoveCoupon();
            } else {
              if (cart.promoCode) {
                cookies.set("tnl-coupon", cart.promoCode);
              }
            }
          }
        })
        .catch((error) => {
          store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
          if (error.message) {
            modalMessage.value = error.message;
          } else {
            modalMessage.value = error;
          }
          modalAlertError.value?.setShowModalError(true);
        });
    };

    const onSaveOrder = async (cart: UserCart) => {
      await cartService.value
        .onSaveOrder(cart, cart.note)
        .then(async (data: Order) => {
          if (data) {
            await transactionService.value.onSaveSocialMedia(
              store.getters.getPlatform,
              store.getters.getUser.socialReferenceId,
              data.id
            );
            await transactionService.value
              .onChangeStatus(data.id, 9)
              .then(async (status: StatusChangeResponse) => {
                if (status.result == 1) {
                  if (cookies.isKey("tnl-coupon")) {
                    cookies.remove("tnl-coupon");
                  }
                  await store.dispatch(
                    TransactionActionTypes.SET_TRANSACTION,
                    data.id.toString()
                  );
                  store.getters.getTransaction.products =
                    data.calculate.products;
                  store.getters.getTransaction.freeProducts =
                    data.calculate.freeProducts;
                  router.push(`/${data.id}?sessionId=${route.query.sessionId}`);
                } else {
                  console.log("error", status.message);
                }
              })
              .catch((error) => {
                store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
                if (error.message) {
                  modalMessage.value = error.message;
                } else {
                  modalMessage.value = error;
                }
                modalAlertError.value?.setShowModalError(true);
              });
          }
        });
    };

    onMounted(() => {
      store.dispatch(GlobalActionTypes.SET_COMPONENT_LOADING, false);
    });

    return {
      modalMessage,
      modalAlertError,
      checkoutCartPanel,
      onBeforeSaveCart,
      onSaveOrder,
      onHideModalError,
    };
  },
});
