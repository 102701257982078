
import { useStore } from "@/store";
import { CartActionTypes } from "@/store/modules/cart/action-types";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import TransactionFreeProduct from "@/components/transaction/transaction-detail/cart/TransactionFreeProduct.vue";
import { Freeproducts } from "@/types/transaction/TransactionPromotion";

export default defineComponent({
  components: {
    TransactionFreeProduct,
  },
  emits: ["onSaveCart"],
  setup(props, { emit }) {
    const store = useStore();
    const transaction = computed(() => store.getters.getCart);
    const refreshCart = computed(() => store.getters.getRefreshCart);
    const freeProductPanel = ref<InstanceType<typeof TransactionFreeProduct>>();
    const onAddQuantity = (index: number) => {
      store.dispatch(CartActionTypes.ADD_QUANTITY, index);
      emit("onSaveCart");
    };
    const onRemoveQuantity = (index: number) => {
      store.dispatch(CartActionTypes.REMOVE_QUANTITY, index);
      emit("onSaveCart");
    };
    const onRemoveAllProduct = (index: number) => {
      store.dispatch(CartActionTypes.REMOVE_ALL_PRODUCT, index);
      emit("onSaveCart");
    };
    const onSubmitFreeProduct = (freeProduct: Freeproducts, index: number) => {
      transaction.value.freeProducts[index].products = freeProduct.products;
      emit("onSaveCart");
    };

    const refreshData = () => {
      freeProductPanel.value?.refreshData();
    };

    return {
      transaction,
      refreshCart,
      backgroundColor: computed(() => store.getters.getBrand.colorThemeMain),
      freeProductPanel,
      refreshData,
      onAddQuantity,
      onRemoveQuantity,
      onRemoveAllProduct,
      onSubmitFreeProduct,
    };
  },
});
