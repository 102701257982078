
import { useStore } from "@/store";
import { CartActionTypes } from "@/store/modules/cart/action-types";
import { Freeproducts } from "@/types/transaction/TransactionPromotion";
import { computed, defineComponent, PropType, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  emits: ["onSaveOrder"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const transaction = computed(() => store.getters.getCart);

    const getMoreProduct = () => {
      router.push(
        `/${route.params.initialId}/product?sessionId=${route.query.sessionId}`
      );
    };
    const onCheckoutCart = () => {
      if (!store.getters.getUserConsent) {
        store.dispatch(CartActionTypes.SET_IS_SHOW_POLICY, false);
        emit("onSaveOrder");
      } else {
        store.dispatch(CartActionTypes.SET_IS_SHOW_POLICY, true);
      }
    };

    return {
      transaction,
      backgroundColor: computed(() => store.getters.getBrand.colorThemeMain),
      isDisableForFreeProduct: computed(
        () => store.getters.getIsFreeProductDisableFlag
      ),

      getMoreProduct,
      onCheckoutCart,
    };
  },
});
