
import { computed, defineComponent, onMounted, ref } from "vue";
import TransactionHeader from "@/components/transaction/transaction-detail/header/TransactionHeader.vue";
import CheckoutCartDetail from "./detail/CheckoutCartDetail.vue";
import CheckoutFooter from "./detail/CheckoutFooter.vue";
import CheckoutPolicy from "./detail/CheckoutPolicy.vue";
import { useStore } from "@/store";
import InputTextCustom from "@/components/inputs/InputText.vue";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  components: {
    TransactionHeader,
    CheckoutCartDetail,
    CheckoutFooter,
    CheckoutPolicy,
    InputTextCustom,
  },
  emits: ["onSaveCart", "onSaveOrder"],
  setup(props, { emit }) {
    const store = useStore();
    const promoCode = ref<string>("");
    const note = ref<string>("");
    const isDisableCoupon = ref<boolean>(false);
    const isDisableUseCoupon = ref<boolean>(true);
    const checkoutCartDetailPanel =
      ref<InstanceType<typeof CheckoutCartDetail>>();
    const { cookies } = useCookies();

    const onSaveCart = () => {
      emit("onSaveCart", 0, promoCode.value, note.value);
    };
    const onSaveOrder = () => {
      emit("onSaveOrder", 1, promoCode.value, note.value);
    };

    const onClearCoupon = () => {
      promoCode.value = "";
      onSaveCart();
    };

    const onUseCoupon = () => {
      if (promoCode.value) {
        isDisableCoupon.value = true;
        onSaveCart();
      }
    };

    const onRemoveCoupon = () => {
      promoCode.value = "";
      isDisableCoupon.value = false;
      onSaveCart();
    };

    const refreshData = () => {
      checkoutCartDetailPanel.value?.refreshData();
    };

    onMounted(() => {
      let oldPromoCode = cookies.get("tnl-coupon");
      if (oldPromoCode) {
        promoCode.value = oldPromoCode;
        onUseCoupon();
      }
    });

    return {
      isShowCartPolicy: computed(() => store.getters.getIsShowCartPolicy),
      promoCode,
      note,
      isDisableCoupon,
      isDisableUseCoupon,
      checkoutCartDetailPanel,
      onSaveCart,
      onSaveOrder,
      onClearCoupon,
      onUseCoupon,
      onRemoveCoupon,
      refreshData,
    };
  },
});
