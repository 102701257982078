import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckoutPanel = _resolveComponent("CheckoutPanel")!
  const _component_ModalAlertError = _resolveComponent("ModalAlertError")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CheckoutPanel, {
      ref: "checkoutCartPanel",
      onOnSaveCart: _ctx.onBeforeSaveCart,
      onOnSaveOrder: _ctx.onBeforeSaveCart
    }, null, 8, ["onOnSaveCart", "onOnSaveOrder"]),
    _createVNode(_component_ModalAlertError, {
      modalMessage: _ctx.modalMessage,
      onOnHideModalError: _ctx.onHideModalError,
      ref: "modalAlertError"
    }, null, 8, ["modalMessage", "onOnHideModalError"])
  ]))
}